
import {defineComponent} from 'vue'
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ReminderName",
  props: {
    reminder: {},
    isPanel : {default: false}
  },
  setup() {
    return{
      ...LoadPanel(),
    }
  },
  methods: {
    onClick(id) {
      if (this.isPanel) {
        this.loadData(id, 'REMINDER')
      } else {
        store.dispatch(Actions.LOAD_REMINDER, id)
      }
    }
  }
})
